@import './shared/helpers/bootstrap_loader';
@import './helpers/globals';

@import './components/ck_fu';
@import './shared/layout/footer';

body {
  background-color: $gray8;
  background-repeat: no-repeat;
  background-image: url("../images/shared_portal/school.jpg");
  background-size: cover;
}

.sp-header-top>.header-brand {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 375px;

  img {
    width: 100%;
    border: none;
  }
}

.sp-sign-in-logo {
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 20px;
  width: 375px;

  img {
    width: 100%;
    border: none;
  }
}

.sp-authentication {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 84%;
  min-height: 550px;
}

.sign-in-content {
  height: 100%;
  min-height: 580px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
  border-radius: 8px;

  main {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
  }
}

.sp-layout-container {
  max-width: 1320px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.25);
}

.sp-footer {
  max-width: 1320px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 20px 20px 5px rgba(0, 0, 0, 0.15);
}
